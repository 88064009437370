import { useRouter } from 'next/router';
import { useMemo, useState } from 'react';
import Cookies from 'universal-cookie';

export const TOKEN_KEY = ["DEV", "LOCAL"].indexOf(process.env.NEXT_PUBLIC_STAGE) > -1 
  ? 'dtoken' 
  : (
      process.env.NEXT_PUBLIC_STAGE === 'STG' 
      ? 'stoken'
      : 'token'
    );

// For now only exporting the fields that are used elsewhere in the app.
export type voyce_auth_jwt = {
  exp: number;
  id: number;
  role: number;
};

export function parseJwt(token: string): voyce_auth_jwt {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(
    Buffer.from(base64, 'base64')
      .toString()
      .split('')
      .map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );

  const result: voyce_auth_jwt = JSON.parse(jsonPayload);

  if (result?.id) {
    result.id = Number(result.id);
  }

  return result;
}

export function useAuthInfo(
  redirectIfUnauthed = false,
  redirectTo = '/'
): [voyce_auth_jwt, string] {
  const router = useRouter();

  const cookies = new Cookies();
  let token = cookies.get(TOKEN_KEY);
  return useMemo(() => {
    if (token) {
      const jwt = parseJwt(token);

      if (Date.now() >= jwt.exp * 1000) {
        cookies.remove(TOKEN_KEY, {
          path: '/',
          domain: '.voyce.me'
        });
        if (redirectIfUnauthed && typeof window !== 'undefined') {
          router.replace(redirectTo);
        }
        return [undefined, undefined];
      }

      return [jwt, token];
    } else {
      if (redirectIfUnauthed && typeof window !== 'undefined') {
        router.replace(redirectTo);
      }
      return [undefined, undefined];
    }
  }, [token]);
}

export function checkAuth(func, router) {
  const cookies = new Cookies();
  let token = cookies.get(TOKEN_KEY);
  if (token === undefined) {
    router.push('/login')
    return;
  }
  func();
}
export function getUserId() {
  const cookies = new Cookies();
  let token = cookies.get(TOKEN_KEY);
  if (token) {
    const jwt = parseJwt(token);

    if (Date.now() >= jwt.exp * 1000) {
      return null;
    }

    return jwt?.id;
  }

  return null;
}
